import { render, staticRenderFns } from "./ChangePasswordPage.vue?vue&type=template&id=01abb8b0&scoped=true&"
import script from "./ChangePasswordPage.vue?vue&type=script&lang=ts&"
export * from "./ChangePasswordPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01abb8b0",
  null
  
)

export default component.exports