


































































































import UserAccountModule from '@/store/modules/UserAccountModule';
import { Component, Vue } from 'vue-property-decorator';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class ChangePasswordPage extends Vue {
  newPassword: string | null = null;
  confirmNewPassword: string | null = null;
  showValidateMessage = false;
  email = '';

  async updatePassword() {
    if (!this.validateAll) {
      this.showValidateMessage = true;
    } else {
      this.showValidateMessage = false;
      await UserAccountModule.updatePassword(this.newPassword!);
      if (UserAccountModule.updatePasswordSucceeded) {
        this.newPassword = null;
        this.confirmNewPassword = null;
      }
    }
  }

  get updatePasswordIsProcessing() {
    return UserAccountModule.updatePasswordProcessing;
  }

  get updatePasswordSucceeded() {
    return UserAccountModule.updatePasswordSucceeded;
  }

  get updatePasswordError() {
    return UserAccountModule.updatePasswordError;
  }

  get validateAll(): boolean {
    return (
      this.validatePassword === true &&
      this.validateConfirmPassword === true
    );
  }

  get validatePassword() {
    if (this.newPassword) {
      return this.newPassword!.length >= 8;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get validateConfirmPassword() {
    if (this.confirmNewPassword) {
      return (
        this.confirmNewPassword!.length >= 8 &&
        this.newPassword === this.confirmNewPassword
      );
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }
}
